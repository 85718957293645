import gqlCache from "config/gql-cache";
import { useActivityCommentCreateMutation } from "services/graphql";
import {
  activityTypeToFragmentName,
  writeActivityTypeFragment,
} from "../Activities/utils";

export default () => {
  const [activityCommentCreate] = useActivityCommentCreateMutation();

  return async ({ activityId, activityType, parentCommentId, body }) => {
    const resp = await activityCommentCreate({
      variables: {
        input: {
          contentId: activityId,
          contentType: activityType,
          parentCommentId,
          body,
        },
      },
      update(_, { data }) {
        if (!data.activityCommentCreate) {
          return;
        }

        const newCreatedComment = data.activityCommentCreate;
        const fieldName = !parentCommentId ? "comments" : "replies";

        gqlCache.modify({
          id: gqlCache.identify({
            id: parentCommentId || activityId,
            __typename: !parentCommentId
              ? activityTypeToFragmentName(activityType)
              : "ActivityComment",
          }),
          fields: {
            [fieldName]: existingActivityTypeRef => {
              const newCommentRef = writeActivityTypeFragment(
                "user_comment",
                newCreatedComment
              );

              const { edges = [], pageInfo = {} } = existingActivityTypeRef;

              const updatedEdges = edges.map(edge => ({
                ...edge,
                cursor: `${parseInt(edge.cursor) + 1}`,
              }));

              let newEdges;
              if (parentCommentId) {
                // If the comment is a reply, add it to the replies list at the end
                newEdges = [
                  ...updatedEdges,
                  {
                    __typename: "ActivityCommentEdge",
                    node: {
                      ...newCommentRef,
                    },
                    cursor: `${updatedEdges.length + 1}`,
                  },
                ];
              } else {
                // If the comment is a top-level comment, add it to the comments list at the top
                newEdges = [
                  {
                    __typename: "ActivityCommentEdge",
                    node: {
                      ...newCommentRef,
                    },
                    cursor: "1",
                  },
                  ...updatedEdges,
                ];
              }

              const newPageInfo = {
                ...pageInfo,
                endCursor: `${parseInt(pageInfo.endCursor) + 1}`,
              };

              const updatedActivityTypeRef = {
                ...existingActivityTypeRef,
                edges: newEdges,
                pageInfo: newPageInfo,
                totalCount: parseInt(existingActivityTypeRef.totalCount) + 1,
              };

              return updatedActivityTypeRef;
            },
          },
        });
      },
    });
    return resp.data?.activityCommentCreate;
  };
};
